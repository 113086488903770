"use client";
import React from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ApolloProviderWrapper } from "./apolloProvider";

const queryClient = new QueryClient();

const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ApolloProviderWrapper>{children}</ApolloProviderWrapper>
      </QueryClientProvider>
    </Provider>
  );
};

export default ReduxProvider;
