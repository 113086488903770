"use client";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getCookie } from "cookies-next";

const httpLink = createHttpLink({
  uri: "https://dashboard.vulnvision.com/graphql/",
});

const debugLink = new ApolloLink((operation, forward) => {
  console.log(`Starting request for ${operation.operationName}`);

  return forward(operation).map((data) => {
    console.log(`Ending request for ${operation.operationName}`, data);
    return data;
  });
});

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const token =
    useSelector((state: RootState) => state.user.token) || getCookie("token");

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
        // "Content-Type": "application/json",
        // ContentType: "application/json",
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([debugLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
