import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Blog } from "../types";

interface BlogState {
  posts: Array<Blog>;
  loading: boolean;
  error: string | null;
}

const initialState: BlogState = {
  posts: [],
  loading: false,
  error: null,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<Blog[]>) => {
      state.posts = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setPosts, setLoading, setError } = blogSlice.actions;
export default blogSlice.reducer;
