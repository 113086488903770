import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import baseUrl from "@/api/baseUrl";

export const baseQuery = async (args: any, api: any, extraOptions: any) => {
  const fetchArgs = typeof args === "function" ? args(api) : args;

  // Log the request details
  console.log("Request details:", {
    url: fetchArgs.url,
    params: fetchArgs.params,
  });

  const fetchBase = fetchBaseQuery({
    baseUrl,
    // credentials: "include", // include || omit || same-origin
    // prepareHeaders: (headers, { getState }) => {},
  });

  const method = fetchArgs.method || "GET";

  try {
    const result = await fetchBase(fetchArgs, api, extraOptions);
    if (result.error) {
      if (!extraOptions?.skipToast) {
        console.error(result.error);
      }
      return result;
    }

    if (!extraOptions?.skipToast) {
      console.log("fetchArgs.method", method);
      console.log(method);
    }
    return result;
  } catch (error) {
    if (!extraOptions?.skipToast) {
      console.error(error);
    }
    throw error;
  }
};

// Home
export const apiHomeSlice = createApi({
  reducerPath: "homeApi",
  baseQuery: baseQuery,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Home"],
});

// auth
export const apiAuthSlice = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery,
  refetchOnFocus: true,
  endpoints: (builder) => ({}),
  tagTypes: ["Auth"],
});
