import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setCookie, deleteCookie } from "cookies-next";

interface UserProfile {
  id: string;
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  image?: string;
  isCustomer: boolean;
  createdAt: string;
  updatedAt: string;
}

interface UserState {
  profile: UserProfile | null;
  token: string | null;
  userId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  profile: null,
  token: null,
  userId: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
      state.userId = action.payload.id;
      state.loading = false;
      state.error = null;
      setCookie("userId", action.payload.id, { maxAge: 30 * 24 * 60 * 60 }); // 30 days
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      setCookie("token", action.payload, { maxAge: 30 * 24 * 60 * 60 }); // 30 days
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
      setCookie("userId", action.payload, { maxAge: 30 * 24 * 60 * 60 }); // 30 days
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      state.profile = null;
      state.token = null;
      state.userId = null;
      state.loading = false;
      state.error = null;
      deleteCookie("token");
      deleteCookie("userId");
    },
  },
});

export const {
  setUserProfile,
  setToken,
  setUserId,
  setLoading,
  setError,
  logout,
} = userSlice.actions;
export default userSlice.reducer;
